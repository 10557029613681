/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import {
    DTO, DTORef, DTOMetaData, DTOMethods, DTOClassMethods,
    BoundEndpointAPI, Attachment, EventAttachment
} from '../dto';

import { Event_v1 } from './event-v1';
import { EventType } from './eventtype';

export interface Objective_v1 extends Event_v1, DTOMethods<Objective_v1> {
    eventType: EventType;
    startDate: Date;
    endDate: Date;
    isAllDay: boolean;
    status: string;
    priority: number;
    rank: number;
    createdDate: Date;
    completedDate: Date;
    progress: number;
    duration: number;
    durationType: string;
    durationUnit: string;
    startTimezone: string;
    endTimezone: string;
    isReadOnly: boolean;
    children: DTORef[];
    files: EventAttachment[];
    resolvedUsers: Attachment[];
}
export const Objective_v1: DTOMetaData & DTOClassMethods<Objective_v1> = {
    _label:'Objectives',
    _dto: 'Objective_v1',
    _type: 'objective',
    _colors: { primaryThemeColor: "#6d9b44", secondaryThemeColor: "#558a1f", tertiaryThemeColor: "#558a1f" },
    _keysets: ["id"], // TODO: Generate from DTO Schema
    _properties: [
        { label: 'Event type', prop: 'eventType', hint: 'Event type', type: 'enum', fixed: false, source: undefined, values: ["SAAS_CLUSTER_RELEASE", "MANAGED_CLUSTER_RELEASE", "MILESTONE", "PRIVATE_VALUE_EVENT", "PUBLIC_VALUE_EVENT", "DYNATRACE_VALUE_EVENT", "QUARTERLY_BUSINESS_REVIEW", "VELOCITY_SERVICES_EVENT", "ESA_SERVICES_EVENT", "EPM_SERVICES_EVENT", "PROJECT_MEETING", "TRAINING_SESSION", "OBJECTIVE", "GOAL", "TASK", "PROJECT", "COVERAGE_GOAL", "COVERAGE_OBJECTIVE", "ISSUE", "RISK"], default: EventType.OBJECTIVE },
        { label: 'Start date', prop: 'startDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'End date', prop: 'endDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Is all day', prop: 'isAllDay', hint: 'Yes/No', type: 'boolean', fixed: true, source: undefined, values: undefined, default: false },
        { label: 'Status', prop: 'status', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "New" },
        { label: 'Priority', prop: 'priority', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined, default: 0 },
        { label: 'Rank', prop: 'rank', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined, default: 0 },
        { label: 'Created date', prop: 'createdDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Completed date', prop: 'completedDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Rag', prop: 'rag', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "" },
        { label: 'Progress', prop: 'progress', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined, default: 0 },
        { label: 'Duration', prop: 'duration', hint: 'Decimal value (7 decimals)', type: 'number', fixed: true, source: undefined, values: undefined, default: 0 },
        { label: 'Duration type', prop: 'durationType', hint: 'Duration type', type: 'enum', fixed: false, source: undefined, values: ["FIXEDDURATION", "FIXEDEND"], default: "FIXEDEND" },
        { label: 'Duration unit', prop: 'durationUnit', hint: 'Duration unit', type: 'enum', fixed: false, source: undefined, values: ["HOUR", "DAY", "WEEK", "MONTH", "QUARTER", "YEAR"], default: "DAY" },        { label: 'Start timezone', prop: 'startTimezone', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "" },
        { label: 'End timezone', prop: 'endTimezone', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "" },
        { label: 'Is read only', prop: 'isReadOnly', hint: 'Yes/No', type: 'boolean', fixed: true, source: undefined, values: undefined, default: false },
    ],
    _related: [
        { prop: 'children', type: 'task', class: 'Task_v1', label: 'Tasks' },
    ],
    _endpoint: '/api/pmo/v1.0/objective/v1.0',
    _docLink: '/wiki/elevate/Assets/objective/',
    _depth: 2,
    _childrenAt: 'children',
    _parentDTONames: 'Goal_v1,CoverageGoal_v1',
    _icon: './assets/dtos/objective.svg',
    _rootDTOName: 'Project_v1',
    endpointAPI: new BoundEndpointAPI<Objective_v1>('Objective_v1', DTO),
    from: (obj: any): Objective_v1 => {
        return Object.setPrototypeOf(obj, Objective_v1._prototype);
    },
    select: (p: any = ''): any => {
        return Objective_v1.endpointAPI.get(p).then(res =>
            !res ? undefined
            : Array.isArray(res) ? res.map(a => Objective_v1.from(a) as Objective_v1)
            : Objective_v1.from(res)
    )}
}
